import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { useParams,useHistory } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import config from "../config";
import "./CreateNFT.css";
import { API } from "aws-amplify";
import { s3Upload } from "../lib/awsLib";
import { useFormFields } from "../lib/hooksLib";
import { useAppContext } from "../lib/contextLib";
import Footer from "./Footer";

export default function CreateManagedNFT() {
  const file = useRef(null);
  const history = useHistory();
  const {cid } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const { userOrg, currentOrgObj } = useAppContext();
  const [acceptDonation, setacceptDonation] = useState(false);
  const [displayInfo, setdisplayInfo] = useState(false);
  const [dispMsg, setdispMsg] = useState([]);
  const [errormsgFormat, seterrormsgFormat] = useState(true);
  var jsonUserOrg = "ORG#" + userOrg;
  const onSwitchAction = () => {
    setacceptDonation(!acceptDonation);
  };


  const [fields, handleFieldChange] = useFormFields({
    tokenName: "",
    tokenChain: "ALGO",
    description: "",
    descrShort: "",
    qty: "1",
    orgName: jsonUserOrg,
    imageURL: "",
    imagePublicURL: "",
    assetID: "",
    senderAddress: "",
    action: "",
    externalURL: "",
    assetURL: "",
    tier1MinAmt:25,
    tier2MinAmt:50,
    campaignName:cid,
    acceptDonation:false
  });


  function handleFileChange(event) {
    file.current = event.target.files[0];
  }






  async function createMetaDataJSON(fields) {


    let metadata = {
      name: fields.tokenName,
      description: fields.description,
      image: fields.imagePublicURL

    }

    const metaDatafile = new Blob([JSON.stringify(metadata)]);
    const metaDataKey = userOrg + "_NFT_metadata.json";
    const metaDataURL = metaDataKey ? await s3Upload(metaDatafile, metaDataKey) : null;

    let metadataPublicURL = config.imageURL + metaDataURL + "#arc3";
    return metadataPublicURL;
  }



  async function handleSubmit(event) {

    setdisplayInfo(false);

    event.preventDefault();

    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }

    setIsLoading(true);

    try {
      const imageKey = userOrg + "_NFT." + file.current.name.split(".")[1];
      const imageURL = file.current ? await s3Upload(file.current, imageKey) : null;
      fields.imageURL = imageURL;
      fields.imagePublicURL = config.imageURL + imageURL;
      fields.assetURL = await createMetaDataJSON(fields);
      fields.action = "createAsset";
      fields.acceptDonation=acceptDonation;
      await createNFT(fields);

      setIsLoading(false);
      history.push(`/org/${userOrg}/admin/campaigns/${cid}`);
    } catch (e) {
      seterrormsgFormat(true);
      setdispMsg("Error:" + e.message);
      console.log("Error:" + e.message);
      setdisplayInfo(true);
      setIsLoading(false);
    }

  }

  function validateForm() {
    //console.log("file" + file);
    return (fields.tokenName.length > 0 &&
      fields.tokenName.length <= 32 &&
      fields.description.length > 0 &&
      fields.descrShort.length > 0 &&
      file)
  }

  const DisplayInfo = () => (
    <div>
      {errormsgFormat ?
        <div id="dispInfo" className="alert alert-danger   padding-lg " role="alert">
          <span>{dispMsg}</span>
        </div>
        :
        <div id="dispInfo" className="alert alert-success   padding-lg " role="alert">
          <span>{dispMsg}</span>
        </div>
      }
    </div>
  );

  async function createNFT(nft) {

    //console.log("Asset ID", nft.assetID);
    //console.log("Asset ID", nft.senderAddress);
    return API.post("mygiving", "/managednft", {
      body: nft
    });
  }
  return (
    <div className="mainContainer">
      <div className="container shadow-lg p-3 mb-5 bg-white rounded CreateNFT px-2 ">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mt-3" size="lg" controlId="tokenName">
            <Form.Label className="fw-bold">Digital Asset Name</Form.Label>
            <Form.Control
              type="text"
              value={fields.tokenName}
              onChange={handleFieldChange}
              placeholder="Enter the name of your Digital Asset (27 chars max)"
            />
          </Form.Group>
          <Form.Group className="mt-3" size="lg" controlId="orgName">
            <Form.Label className="fw-bold">Org Name</Form.Label>
            <Form.Control
              value={userOrg}
              plaintext
              readOnly
            />
          </Form.Group>
          <Form.Group className="mt-3" size="lg" controlId="description">
            <Form.Label className="fw-bold">Digital Asset Description</Form.Label>
            <Form.Control
              as="textarea"
              value={fields.description}
              onChange={handleFieldChange}
              placeholder="Provide detailed description"
            />
          </Form.Group>
          <Form.Group className="mt-3" size="lg" controlId="descrShort">
            <Form.Label className="fw-bold">Digital Asset Short Description</Form.Label>
            <Form.Control
              type="text"
              value={fields.descrShort}
              onChange={handleFieldChange}
              placeholder="Provide short description"
            />
          <Form.Group className="mt-3" size="lg" controlId="campaignName">
            <Form.Label className="fw-bold">Campaign Name</Form.Label>
            <Form.Control
              type="text"
              value={fields.campaignName}
              plaintext readOnly
            />
          </Form.Group>             
          </Form.Group>
          <Form.Group className="mt-3" size="lg" controlId="tokenChain">
            <Form.Label className="fw-bold">Token Chain</Form.Label>
            <Form.Control
              value="ALGO"
              plaintext readOnly
            />
          </Form.Group>
          <Form.Group className="mt-3" size="lg" controlId="externalURL">
            <Form.Label className="fw-bold">External URL</Form.Label>
            <Form.Control
              as="textarea"
              value={fields.externalURL}
              onChange={handleFieldChange}
              placeholder="Link any external URL for this campaign"
            />
          </Form.Group>      
          <Form.Group className="mt-3" size="lg" controlId="qty">
            <Form.Label className="fw-bold">Number of tokens (Enter 1 for NFT)</Form.Label>
            <Form.Control
              min="0"
              type="number"
              value={fields.qty}
              onChange={handleFieldChange}
              placeholder="1"
            />
          </Form.Group>
          <Form.Group className="mt-3" controlId="acceptDonation">
            <Form.Label className="fw-bold">Accept Donation </Form.Label>
            <Form.Switch
              onChange={onSwitchAction}
              id="custom-switch"
              label="Show Donate Button for this NFT?"
              checked={acceptDonation}

            />
          </Form.Group>               
          <Form.Group className="mt-3" size="lg" controlId="tier1MinAmt">
            <Form.Label className="fw-bold">Minimum Donation for an Asset Transfer</Form.Label>
            <Form.Control
              min="0"
              type="number"
              value={fields.tier1MinAmt}
              onChange={handleFieldChange}
              placeholder="25"
            />
          </Form.Group>
          <Form.Group className="mt-3" size="lg" controlId="tier2MinAmt">
            <Form.Label className="fw-bold">Minimum donation for a Personalized NFT</Form.Label>
            <Form.Control
              min="0"
              type="number"
              value={fields.tier2MinAmt}
              onChange={handleFieldChange}
              placeholder="50"
            />
           
          </Form.Group>      
          <Form.Group className="mt-3" size="lg" controlId="imageURL">
            <Form.Label className="fw-bold">Attachment</Form.Label>
            <Form.Control className="fileChoose" onChange={handleFileChange} type="file" />
          </Form.Group>
          <div className="mx-auto justify-content-md-center mb-3">
            <LoaderButton
              block
              type="submit"
              size="lg"
              variant="primary"
              className="mt-3 mx-auto justify-content-md-center"
              isLoading={isLoading}
              disabled={!validateForm()}
              style={{ 'backgroundColor': currentOrgObj.orgColorTheme }}
            >
              Create Asset
            </LoaderButton>
          </div>

        </Form>
        <div>

          {displayInfo ? <DisplayInfo /> : null}
        </div>
      </div>
      {!isLoading ? <Footer /> : null}
    </div>
  );
}