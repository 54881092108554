
import { Button, Card, Row, Col } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useAppContext } from "../lib/contextLib";
import { onError } from "../lib/errorLib";
import { Link } from "react-router-dom";
import { API, Storage } from "aws-amplify";
import "./AdminHome.css";
import Footer from "./Footer";

export default function Home() {
  const [nfts, setnfts] = useState([]);


  const { userOrg } = useAppContext();
  const {currentOrgObj, setcurrentOrgObj,setcurrentOrgLogo} = useAppContext();
  const { isAuthenticated } = useAppContext();

  const [isLoading, setIsLoading] = useState(true);
  //console.log("Org", userOrg+"~"+JSON.stringify(currentOrgObj,2));
  useEffect(() => {

    async function onLoad() {

      try {

        var org = await API.get("mygiving", `/organization/${userOrg}`);
        setcurrentOrgObj(org);
        setcurrentOrgLogo(org.orgBannerLogo);
        var nfts = await loadNfts();

        for (var i = 0; i <= nfts.length - 1; i++) {
          if (nfts[i].imageURL) {
            nfts[i].imageURL = await Storage.get(nfts[i].imageURL);
          }

        }

        setnfts(nfts);
      } catch (e) {
        onError("awld" + e);
      }

      setIsLoading(false);
    }

    async function loadNfts() {
      return API.get("mygiving", `/organization/${userOrg}/NFTS`);
    }


    onLoad();
  }, [isAuthenticated, userOrg,setcurrentOrgObj, setcurrentOrgLogo]);


  function rendernftsList(nfts) {
    return (
      <>
        <br />

        <div className="button">
          <Link to={currentOrgObj.orgKey? `/org/${userOrg}/admin/nft/create`: `/org/${userOrg}/admin/nft/new`}>
            <Button style={{'backgroundColor':currentOrgObj.orgColorTheme}} >
              Mint a New Token
            </Button>
          </Link>

        </div>

        <Row xs={1}   className="g-4 mx-auto justify-content-md-center row">
          {nfts.map(({ sk, description, imageURL, name, qty, tokenAddress, tokenChain, createdAt }) => (
            <Col key={sk}>
              <Card className="AdminOrgListItem-main-card mx-auto "  >
                <div className="imageHolder mx-auto">
                  <Card.Img className="AdminOrgListItem-img mx-auto" src={imageURL} />
                </div>
                <Card.Body>
                  <Card.Title className=" fw-bold text-bold text-center">{name}</Card.Title>
                  <Card.Text className="small text-center">
                    Total Qty:{qty}
                  </Card.Text>
                </Card.Body>
                <Card.Footer className="justify-content-md-center mx-auto">
                  <Link to={ `/org/${userOrg}/admin/nfts/${sk.split("#")[1]}`}>
                    <Button style={{'backgroundColor':currentOrgObj.orgColorTheme}}>Details</Button>
                  </Link>
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>

      </>
    );
  }

  function rendernfts() {
    return (
      <div className="nfts">
        <h2 className="pb-3 mt-4 mb-3 border-bottom ">Your NFTs</h2>
        {!isLoading && rendernftsList(nfts)}
      </div>
    );
  }

  return (
    <div className="Home">

      {rendernfts()}
      {!isLoading ? <Footer />:null}
    </div>
  );
}