
import React from "react";
import "./Resources.css";
import { useAppContext } from "../lib/contextLib";
import Logo from '../images/-logos_blue.png';  //  src/images
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
//import acceptInstructions from "https://demonfts.mygiving.io/public/How_to_accept_your_NFT_or_digital_asset_from_MyGiving.pdf";

export default function Resources() {
    let history = useHistory();
    console.log("process.env.REACT_APP_PUBLIC_BUCKET:"+process.env.REACT_APP_PUBLIC_BUCKET);
    const {  setcurrentOrgLogo  } = useAppContext();
    setcurrentOrgLogo(Logo);

    function renderLander() {
        return (
            <div className="lander">
                <section className="bg-dark text-white sectionpad">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg sectionpad2  ">
                                <h2 className="">Resources</h2><br />                                 
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-light sectionpad">
                    <div>
                        <br />
                        <div className="row justify-content-md-center">


                            <div>
                                
                                <a href={process.env.REACT_APP_PUBLIC_BUCKET+"How_to_accept_your_NFT_or_digital_asset_from_MyGiving.pdf"} rel="noopener noreferrer" target="_blank" >How to accept your NFT or digital asset?</a>
                            </div>                         

                           
                        </div>

                    </div>
                </section >

              
            </div >
        );
    }

 


    return (
        <div className="Resources">

            {renderLander()}
            <Button className="btn-sm" onClick={() => history.goBack()}>Back</Button>
        </div>
    );
}