import "./PublicNFT.css";
import { Card, Row, Col, Table, Modal, Button, InputGroup, Dropdown } from "react-bootstrap";
import React, { useState, useEffect, } from "react";
import { useParams } from "react-router-dom";
import { onError } from "../lib/errorLib";
import { API, Storage } from "aws-amplify";
import Form from "react-bootstrap/Form";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../lib/contextLib";

import { LinkedinShareButton, TwitterShareButton, FacebookShareButton } from "react-share";
import { FacebookIcon, LinkedinIcon, TwitterIcon } from "react-share";
import config from "../config";
import Footer from "./Footer";


export default function PublicNFT() {

    const [nfts, setnfts] = useState([]);
    const [holderArray, setholderArray] = useState([]);
    const [nftCollectionArray, setnftCollectionArray] = useState([]);
    
    const [pageTitle, setpageTitle] = useState("");
    const [externalURL, setexternalURL] = useState("");
    const { id, nft } = useParams();
    const [availQty, setavailQty] = useState("0");
    const { currentOrgObj, setcurrentOrgObj, setcurrentOrgLogo } = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [showStripeResult, setshowStripeResult] = useState(false);
    const [selectDDvalue, setselectDDvalue] = useState('');
    const [selectDDTitle, setselectDDTitle] = useState("Select your options");
    const [tier1MinAmt, settier1MinAmt] = useState("");
    const [tier2MinAmt, settier2MinAmt] = useState("");
    //const [stripeStatus,setstripeStatus]=useState(false);

    const handleStripeResultClose = () => setshowStripeResult(false);
    const handleStripeResultShow = () => setshowStripeResult(true);
    const [stripeMessage, setstripeMessage] = useState({});
    const [showDonate, setshowDonate] = useState(false);
    const [verifyDonateisLoading, setverifyDonateisLoading] = useState(false);
    const handleCloseDonate = () => setshowDonate(false);
    const handleShowDonate = () => setshowDonate(true);
    const [donateAmount, setdonateAmount] = useState(0);
    const [donateEmail, setdonateEmail] = useState("");
    const [donateFirstName, setdonateFirstName] = useState("");
    const [donateLastName, setdonateLastName] = useState("");



    const handleSelectOptionDD = (e) => {
        setselectDDvalue(e);
        switch (e) {
            case "0": setselectDDTitle("No, I just want to support");
                break;
            case "1": setselectDDTitle("$" + nfts[0].tier1MinAmt + "+,Shown Asset");
                break;
            case "2": setselectDDTitle("$" + nfts[0].tier2MinAmt + "+, Unique NFT");
                break;
            default: setselectDDTitle("$" + nfts[0].tier2MinAmt + "+, Unique NFT");

        }
    }




    let search = window.location.search;
    let params = new URLSearchParams(search);
    const stripeStatus = params.get('status');



    function validateDonateForm() {
        //console.log("file" + file);
        return (donateEmail.length > 0 && donateLastName.length > 0 && donateFirstName.length > 0);
    }









    useEffect(() => {
        async function onLoad() {

            try {
                var org = await API.get("mygiving", `/organization/${id}`);
                setcurrentOrgObj(org);
                setcurrentOrgLogo(org.orgBannerLogo);

                var nftsDetails = await API.get("mygiving", `/organization/${id}/NFTS/${nft}`);
                var nfts = nftsDetails['nft'];
                var holderArray = nftsDetails['assetHolders'].holdersArray;
                var nftCollectionArray = nftsDetails['NFTCollections'];
                settier2MinAmt(nfts[0].tier2MinAmt);
                settier1MinAmt(nfts[0].tier1MinAmt);
                setdonateAmount(nfts[0].tier2MinAmt);

                //console.log(nfts);

                //Object.entries(nfts).forEach(async (nft)=>{nft.imageURL=await Storage.vault.get(nft.imageURL)});
                for (var i = 0; i <= nfts.length - 1; i++) {
                    if (nfts[i].imageURL) {
                        setpageTitle(nfts[i].name);
                        setexternalURL(nfts[i].externalURL);
                        nfts[i].imageURL = await Storage.get(nfts[i].imageURL, { level: 'public' });
                    }

                }
                //nfts[4].imageURL = await Storage.vault.get(nfts[4].imageURL);
                setnfts(nfts);
                setholderArray(holderArray);
                setnftCollectionArray(nftCollectionArray);
                console.log(holderArray);
                console.log(nftCollectionArray);
                setavailQty(nftsDetails['assetHolders'].totalQty);

            } catch (e) {
                onError(e);
            }

            setIsLoading(false);

            let msg = {};
            if (stripeStatus === "success") {
                msg.header = "Thank you for your donation!"
                msg.body = "Please check your email for detailed instructions to claim your token"
            } else {
                msg.header = "Ouch! Sorry, it wasn't you!"
                msg.body = "Could you please try again?"
            }
            if (stripeStatus && stripeStatus.length > 0) {
                setstripeMessage(msg);
                handleStripeResultShow();
                //setstripeStatus(stripeStatus);

            }


        }
        onLoad();
    }, [id, nft, setcurrentOrgObj, setcurrentOrgLogo, stripeStatus]);

    /*
    function loadNfts() {
        return API.get("mygiving", `/organization/${id}/NFTS`);
    }
    */



    const linkTag = (assetID) => { return (<><a target="_blank" rel="noreferrer" href={config.PURESTAKE_API + "/asset/" + assetID}>{assetID}</a></>) };
    const linkCampaign = (campaign) => { return (<><a target="_blank" rel="noreferrer" href={window.location.origin + `/org/${id}/${campaign}`}>{campaign}</a></>) };

    function rendernftsList(nfts) {
        return (
            <>
                <div className="">
                    <div className="row">
                        <h4>{nfts[0].description}</h4>
                        <Row xs={1} className="g-4 mx-auto justify-content-md-center">
                            {nfts.map(({ sk, description, imageURL, name, descrShort, qty, campaignName, tokenChain, createdAt, assetID, assetHolders }) => (
                                <Col key={sk}>
                                    {nfts[0].acceptDonation ?
                                        <div className="d-flex mb-5  mx-auto">
                                            <Button onClick={handleShowDonate} style={{ 'backgroundColor': currentOrgObj.orgColorTheme }} className="successs justify-content-center mx-auto">Donate</Button>
                                        </div> : null}
                                    <Card className="NFTListItem-main-card mx-auto"   >
                                        <div className="imageHolder">
                                            <Card.Img className="NFTListItem-img mx-auto justify-content-md-center" src={imageURL} />
                                        </div>

                                    </Card>
                                    <br />
                                    <Table className="tableDiv">

                                        <tbody>
                                            <tr>
                                                <td>Name</td>
                                                <td className="fw-bold">{name}</td>
                                            </tr>
                                            <tr>
                                                <td>Description</td>
                                                <td className="fw-bold">{description}</td>
                                            </tr>
                                            <tr>
                                                <td>Short Description</td>
                                                <td className="fw-bold">{descrShort}</td>
                                            </tr>
                                            <tr>
                                                <td>Campaign</td>
                                                <td className="fw-bold">{linkCampaign(campaignName)}</td>
                                            </tr>                                            
                                            <tr>
                                                <td>Total Quantity </td>
                                                <td className="fw-bold"> {qty}</td>
                                            </tr>
                                            <tr>
                                                <td>Available Quantity </td>
                                                <td className="fw-bold"> {availQty}</td>
                                            </tr>
                                            <tr>
                                                <td>Asset Details</td>
                                                <td className="fw-bold"> {linkTag(assetID)}</td>
                                            </tr>

                                        </tbody>
                                    </Table>

                                </Col>
                            ))}

                        </Row>


                    </div>

                    <div className="container">
                        <div className="row row-cols-auto">
                            <FacebookShareButton className="px-2" url={window.location} title="Please donate">
                                <FacebookIcon round size={32} />
                            </FacebookShareButton>
                            <TwitterShareButton className="px-2" url={window.location} title="Please donate">
                                <TwitterIcon round size={32} />
                            </TwitterShareButton>
                            <LinkedinShareButton className="px-2" url={window.location} title="Please donate">
                                <LinkedinIcon round size={32} />
                            </LinkedinShareButton>

                        </div>
                    </div>

                    <div className="row  ">

                        <h3>Donors</h3>
                        {nftCollectionArray.length>0?
                        <Table className="tableDiv">
                            <thead>
                                <tr>
                                    <th>NFT Collection Holders, ${nfts[0].tier2MinAmt}+</th>
                                </tr>
                            </thead>

                            <tbody>
                                {nftCollectionArray.map((nft, index) => {
                                    return (

                                        <tr key={index} data-item={nft}  >
                                            <td>

                                                <a target="_blank" rel="noreferrer" href={window.location.origin + `/org/${id}/nfts/` + (nft.nftID).split("#")[1] }> {nft.name}</a>
                                            </td>

                                        </tr>);
                                })}
                            </tbody>

                        </Table>:null}


                    </div>
                    <div className="row  ">
                        <Table className="tableDiv">
                            <thead>
                                <tr>
                                    <th>Asset Holders </th>
                                </tr>
                            </thead>

                            <tbody>
                                {holderArray.map((address, index) => {
                                    return (

                                        <tr key={index} data-item={address}  >



                                            <td>

                                                <a target="_blank" rel="noreferrer" href={window.location.origin + "/wallets/" + ((address.split("|")[1].length > 0) ? address.split("|")[1] : address.split("|")[0])} >{(address.split("|")[1].length > 0) ? address.split("|")[1] : address.split("|")[0].substring(0, 5) + "..." + address.split("|")[0].substring(address.split("|")[0].length - 5)} </a>
                                            </td>

                                        </tr>);
                                })}
                            </tbody>

                        </Table>


                    </div>
                </div>
            </>
        );
    }



    function rendernfts() {
        return (
            <div className="nfts">
                <div className="pb-3 border-bottom mb-3 mx-auto">
                    <h2 className="mt-4 ">{pageTitle} </h2>
                    <a target="_blank" rel="noreferrer" href={externalURL} className="link-primary">{externalURL}</a>
                </div>
                {!isLoading && rendernftsList(nfts)}
            </div>
        );
    }









    async function handleDonateSubmit(event) {
        event.preventDefault();
        setverifyDonateisLoading(true);
        let astName = nfts[0].name;
        let assetID = nfts[0].assetID;
        let OrgID = "ORG#" + id;
        let client_reference_id = nfts[0].sk;
        let description = nfts[0].description;
        let curAstUrl = window.location.href.split("?")[0];
        let url = await API.post("mygiving", '/billing', {
            body: { assetID, OrgID, client_reference_id, description, curAstUrl, astName, donateAmount, donateEmail, donateFirstName, donateLastName, selectDDvalue }
        });
        setverifyDonateisLoading(false);
        window.location.href = url.url;
        console.log(url.url);
        //handleCloseDonate();
    }

    function updateDonateAmount(amt) {
        setdonateAmount(amt);
        if (amt >= tier2MinAmt) {
            setselectDDvalue("2");
            handleSelectOptionDD("2");
        } else {
            if (amt >= tier1MinAmt) {
                setselectDDvalue("1");
                handleSelectOptionDD("1");
            } else {
                setselectDDvalue("0");
                handleSelectOptionDD("0");
            }
        }

    }

    return (
        <>
            <div className="Home container">
                <div className="mt-1 bannerImg  container" style={{
                    backgroundImage: `url(${currentOrgObj.orgBannerImage})`,
                }}>

                    <div className="text-block  ">
                        <h4>{currentOrgObj.orgWelcomeMessageHeader}</h4>
                        <p>{currentOrgObj.orgWelcomeMessage}</p>
                    </div>


                </div>





                {rendernfts()}

            </div>
            {!isLoading ? <Footer /> : null}
            <Modal
                show={showDonate}
                onHide={handleCloseDonate}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter"><h4 className="fw-bold">Thank you!</h4></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container mt-1 mb-3">
                        <div className="row">
                            <h4 className="mb-2">Your donation</h4>
                            <div className="mb-3">Please donate to  {currentOrgObj.orgName} and help us to reach our goal - Thank you. </div>

                        </div>

                        <div className="row">
                            <div className="col-sm" >
                                <div className="pr-2"><Button style={{ 'backgroundColor': currentOrgObj.orgColorTheme }} onClick={() => { updateDonateAmount(tier1MinAmt) }} className="donateButton btn  btn-lg">${tier1MinAmt}</Button></div>
                            </div>
                            <div className="col-sm">
                                <div className="pr-2"><Button style={{ 'backgroundColor': currentOrgObj.orgColorTheme }} onClick={() => { updateDonateAmount(tier2MinAmt) }} className="donateButton btn   btn-lg">${tier2MinAmt}</Button></div>
                            </div>
                            <div className="col-sm">
                                <div className="pr-2"><Button style={{ 'backgroundColor': currentOrgObj.orgColorTheme }} onClick={() => { updateDonateAmount(100) }} className=" donateButton btn   btn-lg">$100</Button></div>
                            </div>
                        </div>

                        <Form onSubmit={handleDonateSubmit}>
                            <Form.Label className="fw-bold">Amount</Form.Label>
                            <InputGroup className="mb-3">

                                <InputGroup.Text>$</InputGroup.Text>
                                <Form.Control
                                    type="number"
                                    value={donateAmount}
                                    onChange={(e) => { setdonateAmount(e.target.value); updateDonateAmount(e.target.value) }}
                                    placeholder={tier2MinAmt}
                                />
                                <InputGroup.Text>.00</InputGroup.Text>
                            </InputGroup>

                            <div className={tier1MinAmt > 0 ? "" : "d-none"}>
                                <Form.Label className="fw-bold">Options</Form.Label>
                                <Dropdown
                                    id="dropdown-menu-align-right"
                                    onSelect={handleSelectOptionDD}
                                >
                                    <Dropdown.Toggle style={{ 'backgroundColor': currentOrgObj.orgColorTheme }}>{selectDDTitle}</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item style={{ 'backgroundColor': currentOrgObj.orgColorTheme, 'color': "#fff" }} eventKey="0">No, I just want to support</Dropdown.Item>
                                        {donateAmount >= tier1MinAmt ?
                                            <Dropdown.Item style={{ 'backgroundColor': currentOrgObj.orgColorTheme, 'color': "#fff" }} eventKey="1"> ${tier1MinAmt}+, Shown Asset</Dropdown.Item>
                                            :
                                            <Dropdown.Item disabled style={{ 'backgroundColor': '#edf', 'color': "#fff" }} eventKey="1"> ${tier1MinAmt}+, Unique NFT</Dropdown.Item>
                                        }

                                        {donateAmount >= tier2MinAmt ?
                                            <Dropdown.Item style={{ 'backgroundColor': currentOrgObj.orgColorTheme, 'color': "#fff" }} eventKey="2"> ${tier2MinAmt}+, Unique NFT</Dropdown.Item>
                                            :
                                            <Dropdown.Item disabled style={{ 'backgroundColor': '#edf', 'color': "#fff" }} eventKey="2"> ${tier2MinAmt}+, Unique NFT</Dropdown.Item>
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <hr />
                            <h4>Your details</h4>
                            <Form.Group className="mt-3" controlId="donateEmail">
                                <Form.Label className="fw-bold">Email Address</Form.Label>
                                <Form.Control
                                    type="email"
                                    value={donateEmail}
                                    onChange={(e) => setdonateEmail(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mt-3" controlId="donateFirstName">
                                <Form.Label className="fw-bold">First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={donateFirstName}
                                    onChange={(e) => setdonateFirstName(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mt-3" controlId="donateLastName">
                                <Form.Label className="fw-bold">Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={donateLastName}
                                    onChange={(e) => setdonateLastName(e.target.value)}
                                />
                            </Form.Group>
                            <LoaderButton
                                block
                                size="lg"
                                className="mt-3"
                                type="submit"
                                variant="success"
                                isLoading={verifyDonateisLoading}
                                disabled={!validateDonateForm()}
                                style={{ 'backgroundColor': currentOrgObj.orgColorTheme }}
                            >
                                Proceed to Payment
                            </LoaderButton>

                        </Form>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showStripeResult}
                onHide={handleStripeResultClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">{stripeMessage.header}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {stripeMessage.body}
                    <hr />
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>

    );
}