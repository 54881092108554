import React, { useState, useEffect } from "react";
import { Tabs, Tab, Table } from "react-bootstrap";
import { onError } from "../lib/errorLib";
import { API } from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "algorand-walletconnect-qrcode-modal";
import config from "../config";
import { useHistory } from "react-router-dom";
import Logo from '../images/-logos_blue.png';  //  src/images
import { useAppContext } from "../lib/contextLib";
import "./WalletLeaderboard.css";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Footer from "./Footer";
import { Button } from "react-bootstrap";

export default function Settings() {

    const history = useHistory();

    const [walletByOrg, setwalletByOrg] = useState([]);
    const [dataSetByOrg, setdataSetByOrg] = useState();
    const [walletByAsset, setwalletByAsset] = useState([]);
    const [isLoading,setisLoaindg]= useState(true);

    const [orgByHolder, setorgByHolder] = useState([]);
    const { setcurrentOrgLogo } = useAppContext();

    //const [verifyisLoading, setverifyisLoading] = useState(false);
    let prevcount = -1;
    let assetRank = 1;
    let orgRank = 1;
    let holderRank = 1;

    const connector = new WalletConnect({
        bridge: "https://bridge.walletconnect.org",
        qrcodeModal: QRCodeModal,
    });



    useEffect(() => {
        setcurrentOrgLogo(Logo);



        async function onLoad() {
            try {
                var walletDetails = await API.get("mygiving", `/leaderboard`);
                console.log(walletDetails.walletByOrgArray);

                setwalletByOrg(walletDetails.walletByOrgArray);
                setwalletByAsset(walletDetails.walletByAssetArray);
                setorgByHolder(walletDetails.walletByHolderArray);
                console.log(walletDetails.walletByHolderArray);
                let labelByOrg = [];
                let dataByOrg = [];
                for (var i = 0; i < 5; i++) {
                    labelByOrg.push((walletDetails.walletByOrgArray[i].wallet.split("|")[1].length > 0) ? walletDetails.walletByOrgArray[i].wallet.split("|")[1] : walletDetails.walletByOrgArray[i].wallet.split("|")[0].substring(0, 5) + "..." + walletDetails.walletByOrgArray[i].wallet.split("|")[0].substring(walletDetails.walletByOrgArray[i].wallet.split("|")[0].length - 5));
                    dataByOrg.push(walletDetails.walletByOrgArray[i].count);

                }
                const data = {
                    labels: labelByOrg,
                    datasets: [
                        {
                            label: '# of Votes',
                            data: dataByOrg,
                            backgroundColor: [
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(255, 159, 64, 0.2)',
                            ],
                            borderColor: [
                                'rgba(255, 99, 132, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(75, 192, 192, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(255, 159, 64, 1)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                };


                setdataSetByOrg(data);
                setisLoaindg(false);

            } catch (e) {
                onError(e);
            }
        }

        onLoad();
    }, [setcurrentOrgLogo]);
    ChartJS.register(ArcElement, Tooltip, Legend);

    async function walletConnect() {

        try {
            // Create a connector

            //console.log("connector" + connector + "~~~" + connector.connected);

            // Check if connection is already established
            if (connector.connected) {
                // create new session
                //console.log("session before " + connector.connected);
                await connector.killSession();
                //console.log("session killed" + connector.connected);
            }

            if (!connector.connected) {
                // create new session
                connector.createSession();
                //console.log("session created");
            }

            // Subscribe to connection events
            connector.on("connect", async (error, payload) => {
                if (error) {
                    throw error;
                }
                //console.log("on event connect");
                // Get provided accounts
                const { accounts } = payload.params[0];
                //console.log("Connect =" + accounts);
                // setwalletAdd(accounts[0]);
                console.log("config.ALGO_EXP_INDEXER" + config.ALGO_EXP_INDEXER);
                //const url = `https://testnet.algoexplorerapi.io/v2/accounts/${accounts[0]}`;
                const url = config.ALGO_EXP_INDEXER + `accounts/${accounts[0]}`;
                // setisConnectedToWallet(true);

                window.fetch(url, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data) {
                            console.log(data);
                            if (data.account.address === accounts[0] && data.account.amount) {
                                //console.log(data.assets.length, (data.assets.length + 1) * 100000);
                                if (data.account.amount < (data.account.assets.length + 2) * 101000) {
                                    //setwalletBal(0);
                                    // setbalExists(true);
                                    console.log("Minimum Balance did not meet. Funding 0.1 ALGO!");
                                } else {
                                    //setwalletBal(data.account.amount);

                                }
                                console.log("Wallet Balance" + data.account.amount);
                            }
                        }
                    });

                var nickName = await API.get("mygiving", `/checkWeb3AccountExists/${accounts[0]}`);
                if (nickName.hasOwnProperty("nickName")) {
                    //fields.nickName = nickName.nickName;
                    history.push("/wallets/" + nickName.nickName);
                    // setaccountMsg("Your shareable link " + window.location.origin + "/wallets/" + fields.nickName);
                    // setaccountLink(window.location.origin + "/wallets/" + fields.nickName);
                    //  setaccountExists(true);
                } else {
                    history.push(`/wallets/${accounts[0]}`);
                }
            });

            connector.on("session_update", (error, payload) => {
                if (error) {
                    console.log("session update error" + error);
                    throw error;
                }

                // Get updated accounts 
                //const { accounts } = payload.params[0];
                //console.log(accounts);
            });

            connector.on("disconnect", (error, payload) => {
                if (error) {
                    throw error;
                }
            });
            //console.log("done with function");

        } catch (err) {
            console.error(err);
            //setdispMsg("err.message");
            // setdisplayInfo(true);
        }
    }




    const onConnect = async () => {

        try {

            //setisConnectedToWallet(false);
            await walletConnect();
            //console.log("After connecting" + walletAdd);

        }
        catch (e) {
            //setOptInisLoading(false);
            //seterrormsgFormat(true);
            //setdispMsg("No wallet found");
            //setdisplayInfo(true);
        }
    }


    const Connect = () => (
        <div id="connect">
            <LoaderButton
                block
                size="lg"
                type="submit"
                variant="success"

                onClick={onConnect}


            >
                Connect to Your Wallet
            </LoaderButton>

        </div>

    );



    return (
        <div>
        <div className="shadow-lg p-3 mb-5 bg-white rounded CreateNFT px-2 mt-4">
            <Connect />

            <h3 className="mt-4 mb-4">Wallet Leaderboard</h3>

            <Tabs defaultActiveKey="org" id="uncontrolled-tab-example" className="mb-3">
                <Tab className="" eventKey="asset" title="By Assets">
                    <Table className="tableDiv">
                        <thead>
                            <tr>
                                <th>Rank</th>
                                <th>Wallet</th>
                                <th>Number of Assets</th>
                            </tr>
                        </thead>

                        <tbody>
                            {walletByAsset.map((wallets, index) => {



                                if (prevcount > wallets.count) {
                                    assetRank++;

                                }
                                prevcount = wallets.count;

                                return (


                                    <tr key={index} data-item={wallets}  >
                                        <td>{assetRank}</td>
                                        <td>
                                            <a target="_blank" rel="noreferrer" href={window.location.origin + "/wallets/" + ((wallets.wallet.split("|")[1].length > 0) ? wallets.wallet.split("|")[1] : wallets.wallet.split("|")[0])} >{(wallets.wallet.split("|")[1].length > 0) ? wallets.wallet.split("|")[1] : wallets.wallet.split("|")[0].substring(0, 5) + "..." + wallets.wallet.split("|")[0].substring(wallets.wallet.split("|")[0].length - 5)} </a>

                                        </td>
                                        <td>
                                            {wallets.count}
                                        </td>

                                    </tr>);
                            })}

                        </tbody>

                    </Table>


                </Tab>
                <Tab className="align-middle" eventKey="org" title="By Org">
                <div className="chart d-flex p-1 align-middle">
                        {dataSetByOrg ? <Doughnut className="chart" data={dataSetByOrg} /> : null}
                    </div>
                    <div>
                    <Table className="tableDiv">
                        <thead>
                            <tr>
                                <th>Rank</th>
                                <th>Wallet</th>
                                <th>Number of Organizations</th>
                            </tr>
                        </thead>

                        <tbody>
                            {walletByOrg.map((wallets, index) => {



                                if (prevcount > wallets.count) {
                                    orgRank++;

                                }
                                prevcount = wallets.count;

                                return (


                                    <tr key={index} data-item={wallets}  >
                                        <td>{orgRank}</td>
                                        <td>
                                            <a target="_blank" rel="noreferrer" href={window.location.origin + "/wallets/" + ((wallets.wallet.split("|")[1].length > 0) ? wallets.wallet.split("|")[1] : wallets.wallet.split("|")[0])} >{(wallets.wallet.split("|")[1].length > 0) ? wallets.wallet.split("|")[1] : wallets.wallet.split("|")[0].substring(0, 5) + "..." + wallets.wallet.split("|")[0].substring(wallets.wallet.split("|")[0].length - 5)} </a>
                                        </td>
                                        <td>
                                            {wallets.count}
                                        </td>

                                    </tr>);
                            })}

                        </tbody>

                    </Table>
                    </div>
                </Tab>
                <Tab eventKey="holder" title="By Holders">
                    <Table className="tableDiv">
                        <thead>
                            <tr>
                                <th>Rank</th>
                                <th>Organization</th>
                                <th>Number of Holders</th>
                            </tr>
                        </thead>

                        <tbody>
                            {orgByHolder.map((org, index) => {



                                if (prevcount > org.count) {
                                    holderRank++;

                                }
                                prevcount = org.count;

                                return (


                                    <tr key={index} data-item={org.org}  >
                                        <td>{holderRank}</td>
                                        <td>
                                            <a target="_blank" rel="noreferrer" href={window.location.origin + "/org/" + org.org}>{org.org} </a>

                                        </td>
                                        <td>
                                            {org.count}
                                        </td>

                                    </tr>);
                            })}

                        </tbody>

                    </Table>

                </Tab>
            </Tabs>

        </div>
        {!isLoading?<><Button className="btn-sm" onClick={() => history.goBack()}>Back</Button><Footer /></>:null}
        </div>
    );

}