import "./OrgNFT.css";
import { Button, Card, Row, Col, ListGroup } from "react-bootstrap";
import React, { useState, useEffect, } from "react";
import { useParams } from "react-router-dom";
import { onError } from "../lib/errorLib";
import { Link } from "react-router-dom";
import { API, Storage } from "aws-amplify";
import { useAppContext } from "../lib/contextLib";
import Footer from "./Footer";

export default function OrgNFT() {
    const [nfts, setnfts] = useState([]);
    const [org, setorg] = useState([]);
    const { id } = useParams();
    const { setcurrentOrgLogo, setcurrentOrgObj } = useAppContext();

    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        async function onLoad() {



            try {

                var org = await API.get("mygiving", `/organization/${id}`);
                //console.log("disp:"+org);

                setorg(org);




                var nfts = await API.get("mygiving", `/organization/${id}/NFTS`);
                //Object.entries(nfts).forEach(async (nft)=>{nft.imageURL=await Storage.vault.get(nft.imageURL)});
                for (var i = 0; i <= nfts.length - 1; i++) {
                    if (nfts[i].imageURL) {
                        nfts[i].imageURL = await Storage.get(nfts[i].imageURL, { level: 'public' });
                    }

                }
                //nfts[4].imageURL = await Storage.vault.get(nfts[4].imageURL);
                setnfts(nfts);
                //console.log("About to setup org"+org.orgBannerLogo);
                setcurrentOrgLogo(org.orgBannerLogo);
                //setcurrentOrgObj(org);

            } catch (e) {
                onError(e);
            }

            setIsLoading(false);

        }
        onLoad();

    }, [id, setcurrentOrgLogo]);

    /*
    function loadNfts() {
        return API.get("mygiving", `/organization/${id}/NFTS`);
    }
*/
    setcurrentOrgLogo(org.orgBannerLogo);
    setcurrentOrgObj(org);
    //console.log("currentOrg - After:" + currentOrg + "id:", id);
    function rendernftsList(nfts) {
        return (
            <>
                <Row xs={1}   className="g-4 justify-content-md-center">
                    {nfts.map(({ sk, description, imageURL, name, qty, tokenAddress, tokenChain, createdAt }) => (
                        <Col key={sk}>
                            <Card className="OrgListItem-main-card text-center"   >
                                <div className="imageHolder mx-auto">
                                    <Card.Img className="OrgListItem-img mx-auto" src={imageURL} />
                                </div>
                                <Card.Body>
                                    <Card.Title className=" fw-bold text-bold text-center">{name}</Card.Title>
                                    <Card.Text className="small text-center">
                                        Total Qty:{qty}
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer className="mx-auto">
                                    <Link to={`/org/${id}/nfts/${sk.split("#")[1]}`}>
                                        <Button style={{ 'backgroundColor': org.orgColorTheme }}>
                                            Details
                                        </Button>
                                    </Link>
                                </Card.Footer>

                            </Card>
                        </Col>

                    ))}

                </Row>

            </>
        );
    }



    function rendernfts() {
        return (
            <div className="nfts">
                <h2 className="mt-4">{id} Digital Asset Collection</h2>
                {!isLoading ?

                    <div className="mt-1 border-bottom mb-3 mx-auto">
                        <p>{org.orgMissionStatement}</p>

                        <ListGroup className="mb-3" horizontal="sm">
                            <ListGroup.Item>
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold text-bold text-sm">Website</div>
                                    <a target="_blank" rel="noreferrer" href={org.orgWebsite} class="link-primary">{org.orgWebsite}</a>
                                </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold text-bold text-sm">Tax ID</div>
                                    {org.orgTaxID}
                                </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold text-bold text-sm">Org Type</div>
                                    {org.orgType}
                                </div>
                                </ListGroup.Item>
                            <ListGroup.Item>
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold text-bold text-sm">Location</div>
                                    {org.orgLocation}
                                </div>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                    : null}
                {!isLoading && rendernftsList(nfts) }
               
            </div>
        );
    }

    return (
        <div className="Home  container" >
            <div className="mt-1 bannerImg  container" style={{
                backgroundImage: `url(${org.orgBannerImage})`,
            }}>

                <div className="text-block">
                    <h4>{org.orgWelcomeMessageHeader}</h4>
                    <p>{org.orgWelcomeMessage}</p>
                </div>


            </div>
            {rendernfts()}
            {!isLoading ? <Footer />:null}
           
         </div>
    );
}