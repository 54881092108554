const config = {
    // Backend config
    s3: {
      REGION: process.env.REACT_APP_REGION,
      BUCKET: process.env.REACT_APP_BUCKET,
    },
    apiGateway: {
      REGION: process.env.REACT_APP_REGION,
      URL: process.env.REACT_APP_API_URL,
    },
    cognito: {
      REGION: process.env.REACT_APP_REGION,
      USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
      APP_CLIENT_ID: process.env.REACT_APP_USER_POOL_CLIENT_ID,
      IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID,
    },
    imageURL:process.env.REACT_APP_PUBLIC_BUCKET,
    MAX_ATTACHMENT_SIZE: 5000000,
    SENTRY_DSN: "https://818d53846198482db578afdcc0d21c6a@o1091627.ingest.sentry.io/6108833",
    LEDGER:process.env.REACT_APP_LEDGER,
    ALGO_EXP_API:process.env.REACT_APP_ALGO_EXP_API,
    PURESTAKE_API:process.env.REACT_APP_PURESTAKE_API,
    ALGO_EXP_INDEXER:process.env.REACT_APP_ALGO_EXP_INDEXER,
    XAPI_KEY:process.env.REACT_APP_XAPI_KEY,
    ALGO_SERVER:process.env.REACT_APP_ALGO_SERVER,

  };
  
  export default config;