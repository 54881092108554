import { Storage } from "aws-amplify";


export async function s3Upload(file,key) {
  const filename = `${Date.now()}-${key}`;
  const stored = await Storage.put(filename, file, {
    contentType: file.type,
  });

  return stored.key;
}

