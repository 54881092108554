import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";

import config from "../config";
import "./CreateCampaign.css";
import { API } from "aws-amplify";
import { s3Upload } from "../lib/awsLib";
import { useFormFields } from "../lib/hooksLib";
import { useAppContext } from "../lib/contextLib";
import Footer from "./Footer";

export default function CreateCampaign() {
  const file = useRef(null);
  const history = useHistory();


  const [isLoading, setIsLoading] = useState(false);
  const { userOrg, currentOrgObj } = useAppContext();
   const [displayInfo, setdisplayInfo] = useState(false);
  const [dispMsg, setdispMsg] = useState([]);
  const [errormsgFormat, seterrormsgFormat] = useState(true);
  var jsonUserOrg = "ORG#" + userOrg;
 

  const [fields, handleFieldChange] = useFormFields({
    campaignName: "",
    description: "",
    descrShort: "",
    orgName: jsonUserOrg,
    imagePublicURL: "",
    videoPublicURL: "",    
    externalURL: "",
    urlPath:""
  });


  function handleFileChange(event) {
    file.current = event.target.files[0];
  }



  async function handleSubmit(event) {

    setdisplayInfo(false);

    event.preventDefault();

    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }

    setIsLoading(true);

    try {
      const imageKey = userOrg + "_NFT." + file.current.name.split(".")[1];
      const imageURL = file.current ? await s3Upload(file.current, imageKey) : null;
      fields.imageURL = imageURL;
      fields.imagePublicURL = config.imageURL + imageURL;
      await createCampaign(fields);

      setIsLoading(false);
      history.push(`/org/${userOrg}/admin/campaign`);
    } catch (e) {
      seterrormsgFormat(true);
      setdispMsg("Error:" + e.message);
      console.log("Error:" + e.message);
      setdisplayInfo(true);
      setIsLoading(false);
    }

  }

  function validateForm() {
    //console.log("file" + file);
    return (fields.campaignName.length > 0 &&
      fields.campaignName.length <= 32 &&
      fields.description.length > 0 &&
      fields.descrShort.length > 0 &&
      fields.urlPath.length>0 &&
      file)
  }

  const DisplayInfo = () => (
    <div>
      {errormsgFormat ?
        <div id="dispInfo" className="alert alert-danger   padding-lg " role="alert">
          <span>{dispMsg}</span>
        </div>
        :
        <div id="dispInfo" className="alert alert-success   padding-lg " role="alert">
          <span>{dispMsg}</span>
        </div>
      }
    </div>
  );

  async function createCampaign(nft) {

    return API.post("mygiving", "/campaign", {
      body: nft
    });
  }
  return (
    <div className="mainContainer">
      <div className="container shadow-lg p-3 mb-5 bg-white rounded CreateNFT px-2 ">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mt-3" size="lg" controlId="campaignName">
            <Form.Label className="fw-bold">Campaign Name</Form.Label>
            <Form.Control
              type="text"
              value={fields.campaignName}
              onChange={handleFieldChange}
              placeholder="Enter the name of your Campaign"
            />
          </Form.Group>
          <Form.Group className="mt-3" size="lg" controlId="orgName">
            <Form.Label className="fw-bold">Org Name</Form.Label>
            <Form.Control
              value={userOrg}
              plaintext
              readOnly
            />
          </Form.Group>
          <Form.Group className="mt-3" size="lg" controlId="description">
            <Form.Label className="fw-bold">Campaign Description</Form.Label>
            <Form.Control
              as="textarea"
              value={fields.description}
              onChange={handleFieldChange}
              placeholder="Provide detailed description"
            />
          </Form.Group>
          <Form.Group className="mt-3" size="lg" controlId="urlPath">
            <Form.Label className="fw-bold">Campaign URL Path</Form.Label>
            <Form.Control
              type="text"
              value={fields.urlPath}
              onChange={handleFieldChange}
              placeholder="URL Path (no special characters)"
            />
          </Form.Group>          
          <Form.Group className="mt-3" size="lg" controlId="descrShort">
            <Form.Label className="fw-bold">Campaign Short Description</Form.Label>
            <Form.Control
              type="text"
              value={fields.descrShort}
              onChange={handleFieldChange}
              placeholder="Provide short description"
            />
          </Form.Group>
          <Form.Group className="mt-3" size="lg" controlId="externalURL">
            <Form.Label className="fw-bold">External URL</Form.Label>
            <Form.Control
              as="textarea"
              value={fields.externalURL}
              onChange={handleFieldChange}
              placeholder="Link any external URL for this campaign"
            />
          </Form.Group>
          <Form.Group className="mt-3" size="lg" controlId="imageURL">
            <Form.Label className="fw-bold">Attachment</Form.Label>
            <Form.Control className="fileChoose" onChange={handleFileChange} type="file" />
          </Form.Group>
          <div className="mx-auto justify-content-md-center mb-3">
            <LoaderButton
              block
              type="submit"
              size="lg"
              variant="primary"
              className="mt-3 mx-auto justify-content-md-center"
              isLoading={isLoading}
              disabled={!validateForm()}
              style={{ 'backgroundColor': currentOrgObj.orgColorTheme }}
            >
              Create Campaign
            </LoaderButton>
          </div>

        </Form>
        <div>

          {displayInfo ? <DisplayInfo /> : null}
        </div>
      </div>
      {!isLoading ? <Footer /> : null}
    </div>
  );
}