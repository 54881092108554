
import { Button, Card, Row, Col } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useAppContext } from "../lib/contextLib";
import { onError } from "../lib/errorLib";
import { Link } from "react-router-dom";
import { API, Storage } from "aws-amplify";
import "./AdminCampaignHome.css";
import Footer from "./Footer";

export default function AdminCampaignHome() {
  const [campaigns, setcampaigns] = useState([]);


  const { userOrg } = useAppContext();
  const {currentOrgObj, setcurrentOrgObj,setcurrentOrgLogo} = useAppContext();
  const { isAuthenticated } = useAppContext();

  const [isLoading, setIsLoading] = useState(true);
  //console.log("Org", userOrg+"~"+JSON.stringify(currentOrgObj,2));
  useEffect(() => {

    async function onLoad() {

      try {

        var org = await API.get("mygiving", `/organization/${userOrg}`);
        setcurrentOrgObj(org);
        setcurrentOrgLogo(org.orgBannerLogo);
        var campaigns = await loadcampaigns();

        for (var i = 0; i <= campaigns.length - 1; i++) {
          if (campaigns[i].imageURL) {
            campaigns[i].imageURL = await Storage.get(campaigns[i].imageURL);
          }

        }

        setcampaigns(campaigns);
      } catch (e) {
        onError("awld" + e);
      }

      setIsLoading(false);
    }

    async function loadcampaigns() {
      return API.get("mygiving", `/organization/${userOrg}/campaigns`);
    }


    onLoad();
  }, [isAuthenticated, userOrg,setcurrentOrgObj, setcurrentOrgLogo]);


  function rendercampaignsList(campaigns) {
    return (
      <>
        <br />

        <div className="button">
          <Link to={currentOrgObj.orgKey? `/org/${userOrg}/admin/campaign/create`: `/org/${userOrg}/admin/campaign/new`}>
            <Button style={{'backgroundColor':currentOrgObj.orgColorTheme}} >
              Create a New Campaign
            </Button>
          </Link>

        </div>

        <Row xs={1}   className="g-4 mx-auto justify-content-md-center row">
          {campaigns.map(({ sk, descrShort, imagePublicURL, urlPath }) => (
            <Col key={sk}>
              <Card className="AdminOrgListItem-main-card mx-auto "  >
                <div className="imageHolder mx-auto">
                  <Card.Img className="AdminOrgListItem-img mx-auto" src={imagePublicURL} />
                </div>
                <Card.Body>
                  <Card.Title className=" fw-bold text-bold text-center">{descrShort}</Card.Title>
                </Card.Body>
                <Card.Footer className="justify-content-md-center mx-auto">
                  <Link to={ `/org/${userOrg}/admin/campaigns/${urlPath}`}>
                    <Button style={{'backgroundColor':currentOrgObj.orgColorTheme}}>Details</Button>
                  </Link>
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>

      </>
    );
  }

  function rendercampaigns() {
    return (
      <div className="campaigns">
        <h2 className="pb-3 mt-4 mb-3 border-bottom ">Your Campaigns</h2>
        {!isLoading && rendercampaignsList(campaigns)}
      </div>
    );
  }

  return (
    <div className="Home">

      {rendercampaigns()}
      {!isLoading ? <Footer />:null}
    </div>
  );
}