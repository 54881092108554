import config from "../config";

export async function waitForAlgosignerConfirmation(tx) {
    console.log(`Transaction ${tx.txId} waiting for confirmation...`);
    let status = await window.AlgoSigner.algod({
        ledger: config.LEDGER,
        path: '/v2/transactions/pending/' + tx.txId
    });

    while (true) {
        if (status['confirmed-round'] !== null && status['confirmed-round'] > 0) {
            //Got the completed Transaction
            console.log(`Transaction confirmed in round ${status['confirmed-round']}.`);
            break;
        }

        status = await window.AlgoSigner.algod({
            ledger: config.LEDGER,
            path: '/v2/transactions/pending/' + tx.txId
        });
    }

    console.log("Asset ID from status" + JSON.stringify(status, 2));
    return status;
}

export async function waitForAlgoConfirmation(algodClient, txId, timeout) {
    if (algodClient == null || txId == null || timeout < 0) {
        throw new Error("Bad arguments");
    }

    const status = (await algodClient.status().do());
    if (status === undefined) {
        throw new Error("Unable to get node status");
    }

    const startround = status["last-round"] + 1;
    let currentround = startround;
    //console.log("currentround", currentround);
    while (currentround < (startround + timeout)) {
        const pendingInfo = await algodClient.pendingTransactionInformation(txId).do();
        if (pendingInfo !== undefined) {
            if (pendingInfo["confirmed-round"] !== null && pendingInfo["confirmed-round"] > 0) {
                //Got the completed Transaction
                return pendingInfo;
            } else {
                if (pendingInfo["pool-error"] != null && pendingInfo["pool-error"].length > 0) {
                    // If there was a pool error, then the transaction has been rejected!
                    throw new Error("Transaction " + txId + " rejected - pool error: " + pendingInfo["pool-error"]);
                }
            }
        }
        await algodClient.statusAfterBlock(currentround).do();
        currentround++;
    }

    throw new Error("Transaction " + txId + " not confirmed after " + timeout + " rounds!");
}