import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import config from './config';
import { initSentry } from './lib/errorLib';
import TagManager from 'react-gtm-module'

initSentry();

//identify if you are on development or production
//when you build your app process.env.NODE_ENV is set to 'production' 
const env = process.env.NODE_ENV; 

const tagManagerArgs = {
  gtmId: env === "production"
      ? "GTM-K7R9VJG"
      : "GTM-TZPGQ94",
  //the below changes GTM values based on whether dev or production
  auth: env === "production"
      ? "8e6K1NJ_8_45MDK8rNHB2Q"
      : "34CrVBDRLlx63Y8OxHeNgg",
  preview: env === "production"
    ? "env-1"
    : "env-3"
};

//console.log(process.env.NODE_ENV,tagManagerArgs);
TagManager.initialize(tagManagerArgs)

 

Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: "mygiving",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
    ]
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//config.s3.BUCKET
reportWebVitals();
