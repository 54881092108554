import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import Signup from "./containers/Signup";
import CreateNFT from "./containers/CreateNFT";
import CreateManagedNFT from "./containers/CreateManagedNFT";
import OrgNFT from "./containers/OrgNFT";
import NFT from "./containers/NFT";
import PublicNFT from "./containers/PublicNFT";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import ListNFTTransfers from "./containers/ListNFTTransfers";
import Settings from "./containers/Settings";
import AdminHome from "./containers/AdminHome";
import AdminCampaignHome from "./containers/AdminCampaignHome";
import Wallets from "./containers/Wallets";
import Contact from "./containers/Contact"
import FAQ from "./containers/FAQ"
import Resources from "./containers/Resources"
import SuperUser from "./containers/SuperUser";
import WalletLeaderboard from "./containers/WalletLeaderboard";
import Legal from "./containers/Legal";
import AboutUs from "./containers/AboutUs";
import ClaimNFT from "./containers/ClaimNFT";
import CreateCampaign from "./containers/CreateCampaign";
import AdminHomeCampNFTs from "./containers/AdminHomeCampNFTs";
import OrgCampaign from "./containers/OrgCampaign";
import OrgCampaignHome from "./containers/OrgCampaignHome";

export default function Routes() {

  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <UnauthenticatedRoute exact path="/login">
        <Login />
      </UnauthenticatedRoute >
      <UnauthenticatedRoute exact path="/signup">
        <Signup />
      </UnauthenticatedRoute >
      <AuthenticatedRoute exact path="/superuser">
        <SuperUser />
      </AuthenticatedRoute >
      <AuthenticatedRoute exact path="/org/:id/admin">
        <AdminHome />
      </AuthenticatedRoute >
      <AuthenticatedRoute exact path="/org/:id/admin/campaign">
        <AdminCampaignHome />
      </AuthenticatedRoute >
      <AuthenticatedRoute exact path="/org/:id/admin/campaign/create">
        <CreateCampaign />
      </AuthenticatedRoute >
      <AuthenticatedRoute exact path="/org/:id/admin/campaigns/:cid">
        <AdminHomeCampNFTs />
      </AuthenticatedRoute >
      <AuthenticatedRoute exact path="/org/:id/admin/:cid/nft/create">
        <CreateManagedNFT />
      </AuthenticatedRoute >      
      <AuthenticatedRoute exact path="/org/:id/admin/nft/new">
        <CreateNFT />
      </AuthenticatedRoute >
      <AuthenticatedRoute exact path="/org/:id/admin/nft/create">
        <CreateManagedNFT />
      </AuthenticatedRoute >
      <AuthenticatedRoute exact path="/org/:id/settings">
        <Settings />
      </AuthenticatedRoute >
      <AuthenticatedRoute exact path="/org/:id/admin/nfts/:nft">
        <NFT />
      </AuthenticatedRoute >
      <AuthenticatedRoute exact path="/org/:id/admin/nfts/:nft/history">
        <ListNFTTransfers />
      </AuthenticatedRoute >

      <UnauthenticatedRoute exact path="/org/:id/:cid">
        <OrgCampaignHome />
      </UnauthenticatedRoute >
      <UnauthenticatedRoute exact path="/org/:id/nfts/:nft">
        <PublicNFT />
      </UnauthenticatedRoute >
      <UnauthenticatedRoute exact path="/org/:id/nfts/:nft/claim">
        <ClaimNFT />
      </UnauthenticatedRoute >
      <UnauthenticatedRoute exact path="/contactus">
        <Contact />
      </UnauthenticatedRoute >
      <UnauthenticatedRoute exact path="/faq-page">
        <FAQ />
      </UnauthenticatedRoute >
      <UnauthenticatedRoute exact path="/resources">
        <Resources />
      </UnauthenticatedRoute >

      <UnauthenticatedRoute exact path="/wallets/:id">
        <Wallets />
      </UnauthenticatedRoute >
      <UnauthenticatedRoute exact path="/walletboard">
        <WalletLeaderboard />
      </UnauthenticatedRoute >
      <UnauthenticatedRoute exact path="/legal">
        <Legal />
      </UnauthenticatedRoute >
      <UnauthenticatedRoute exact path="/aboutus">
        <AboutUs />
      </UnauthenticatedRoute >
      <UnauthenticatedRoute exact path="/org/:id">
        <OrgCampaign />
      </UnauthenticatedRoute >
      <UnauthenticatedRoute exact path="/org/:id">
        <OrgNFT />
      </UnauthenticatedRoute >

      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>

  );
}