import React, { useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";

import config from "../config";
import "./CreateNFT.css";
import { API } from "aws-amplify";
import { s3Upload } from "../lib/awsLib";
import { useFormFields } from "../lib/hooksLib";
import { useAppContext } from "../lib/contextLib";
import { waitForAlgosignerConfirmation } from "../lib/algosdkclient"
import { } from "../lib/algosdkclient"
import algosdk from "algosdk";
import sha256 from 'crypto-js/sha256';
import Footer from "./Footer";

export default function CreateNFT() {
  const file = useRef(null);
  const history = useHistory();
  const CryptoJS = require('crypto-js');

  //const [tokenName, settokenName] = useState("");
  //const [orgName, setorgName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { userOrg, currentOrgObj } = useAppContext();
  const [acceptDonation, setacceptDonation] = useState(false);
  const [displayInfo, setdisplayInfo] = useState(false);
  const [dispMsg, setdispMsg] = useState([]);
  const [errormsgFormat, seterrormsgFormat] = useState(true);
  var jsonUserOrg = "ORG#" + userOrg;

  const onSwitchAction = () => {
    setacceptDonation(!acceptDonation);
  };

  const [fields, handleFieldChange] = useFormFields({
    tokenName: "",
    tokenChain: "ALGO",
    description: "",
    descrShort: "",
    qty: "1",
    orgName: jsonUserOrg,
    imageURL: "",
    imagePublicURL: "",
    assetID: "",
    senderAddress: "",
    action: "",
    externalURL: "",
    assetURL: "",
    acceptDonation: false
  });


  function handleFileChange(event) {
    file.current = event.target.files[0];
  }







  /*
    async function httpGetAsync(theUrl, callback) {
      var xmlHttp = new XMLHttpRequest();
      xmlHttp.responseType = 'arraybuffer'; 
      xmlHttp.onreadystatechange = function() { 
          if (xmlHttp.readyState === 4 && xmlHttp.status === 200)
              callback(xmlHttp.response);
      }
      xmlHttp.open("GET", theUrl, true); // true for asynchronous 
      xmlHttp.send(null);
  }
  
  



  async function httpGetAsync(theUrl, callback) {
  var xhr = new XMLHttpRequest();
  xhr.open('GET', theUrl, true);
  xhr.responseType = 'arraybuffer'; // this will accept the response as an ArrayBuffer
  xhr.onload = function(buffer) {
      var words = new Uint32Array(buffer),
          hex = '';
      for (var i = 0; i < words.length; i++) {
        hex += words.get(i).toString(16);  // this will convert it to a 4byte hex string
      }
      console.log("value:"+hex);
      callback(hex);
  };
  xhr.send();
  }
  
  

  async function getImage(url){

    let objectURL;
    await fetch(url).then(function(response) {
      console.log(response);
      return response.blob();
    }).then(function(myBlob) {
      objectURL = URL.createObjectURL(myBlob);
    });
    console.log("objectURL:"+objectURL);
    

  }
  */

  async function createMetaDataJSON(fields) {


    let metadata = {
      name: fields.tokenName,
      description: fields.description,
      image: fields.imagePublicURL

    }
    //await getImage(fields.imagePublicURL);

    let metaDataHash = sha256(JSON.stringify(metadata));
    let buffer = Buffer.from(metaDataHash.toString(CryptoJS.enc.Hex), 'hex');
    //console.log("buffer:"+buffer);
    //console.log("metahash"+metaDataHash);
    const metaDatafile = new Blob([JSON.stringify(metadata)]);
    const metaDataKey = userOrg + "_NFT_metadata.json";
    const metaDataURL = metaDataKey ? await s3Upload(metaDatafile, metaDataKey) : null;

    //console.log("metaDataURL:" + config.imageURL+metaDataURL);
    let metadataPublicURL = config.imageURL + metaDataURL;
    return { metadataPublicURL, buffer };
  }

  async function MintNFT(fields) {

    let metaData = await createMetaDataJSON(fields);
    const metaDataHash = new Uint8Array(metaData.buffer);
    //console.log("config.LEDGER" + config.LEDGER+"~"+metaDataHash);
    //try {
    await window.AlgoSigner.connect({
      ledger: config.LEDGER
    });
    const accts = await window.AlgoSigner.accounts({
      ledger: config.LEDGER
    });
    //console.log("config.ALGO_EXP_API"+config.ALGO_EXP_API);
    // const algodClient = new algosdk.Algodv2('', 'https://testnet.algoexplorerapi.io/', '');
    const algodClient = new algosdk.Algodv2('', config.ALGO_EXP_API, '');

    let txParams = await algodClient.getTransactionParams().do();
    txParams.fee = 1000;
    txParams.flatFee = true;

    // let assetName = fields.tokenName + "@arc3";
    let assetName = fields.tokenName;
    let assetURL = metaData.metadataPublicURL + "#arc3";

    const txn = algosdk.makeAssetCreateTxnWithSuggestedParamsFromObject({
      from: accts[0]['address'],
      assetName: assetName,
      unitName: "tokens",
      total: +fields.qty,
      assetURL: assetURL,
      assetMetadataHash: metaDataHash,
      decimals: 0,
      note: window.AlgoSigner.encoding.stringToByteArray(fields.description),
      suggestedParams: { ...txParams }
    });

    const txn_b64 = window.AlgoSigner.encoding.msgpackToBase64(txn.toByte());
    let signedTxs = await window.AlgoSigner.signTxn([{ txn: txn_b64 }]);

    const r = await window.AlgoSigner.send({
      ledger: config.LEDGER,
      tx: signedTxs[0].blob
    })
    //console.log(r);
    const status = await waitForAlgosignerConfirmation(r);
    const assetID = status['asset-index'];
    const senderAddress = accts[0]['address'];
    const returnObj = {
      assetID: assetID,
      senderAddress: senderAddress,
      assetURL: assetURL
    }
    return returnObj;

    /*}
    catch (e) {
      //alert(JSON.stringify(e, null, 2));
      console.log(e);
      seterrormsgFormat(true);
      setdispMsg("Error:" + e.message);
      setdisplayInfo(true);
    }*/

  }


  async function handleSubmit(event) {

    setdisplayInfo(false);

    event.preventDefault();

    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }

    setIsLoading(true);

    try {
      const imageKey = userOrg + "_NFT." + file.current.name.split(".")[1];
      const imageURL = file.current ? await s3Upload(file.current, imageKey) : null;

      //const publicimageURL = file.current ? await s3PublicUpload(file.current) : null;
      //console.log("publicImageURL:" + config.imageURL + imageURL);
      fields.imageURL = imageURL;
      fields.imagePublicURL = config.imageURL + imageURL;
      const returnObj = await MintNFT(fields);
      //console.log(returnObj.assetID);
      fields.assetID = returnObj.assetID;
      fields.senderAddress = returnObj.senderAddress;
      fields.assetURL = returnObj.assetURL;
      fields.action = "createAsset";
      await createNFT(fields);

      setIsLoading(false);
      history.push(`/org/${userOrg}/admin`);
    } catch (e) {
      seterrormsgFormat(true);
      setdispMsg("Error: Please make sure AlgoSigner extension is installed and your wallet has enough balance! Contact support team if you have any questions! ");
      console.log("Error:" + e.message);
      setdisplayInfo(true);
      setIsLoading(false);
    }

  }

  function validateForm() {
    //console.log("file" + file);
    return (fields.tokenName.length > 0 &&
      fields.tokenName.length <= 32 &&
      fields.description.length > 0 &&
      fields.descrShort.length > 0 &&
      file)
  }

  const DisplayInfo = () => (
    <div>
      {errormsgFormat ?
        <div id="dispInfo" className="alert alert-danger   padding-lg " role="alert">
          <span>{dispMsg}</span>
        </div>
        :
        <div id="dispInfo" className="alert alert-success   padding-lg " role="alert">
          <span>{dispMsg}</span>
        </div>
      }
    </div>
  );

  async function createNFT(nft) {

    //console.log("Asset ID", nft.assetID);
    //console.log("Asset ID", nft.senderAddress);
    return API.post("mygiving", "/nft", {
      body: nft
    });
  }
  return (
    <div className="mainContainer">
      <div className="container shadow-lg p-3 mb-5 bg-white rounded CreateNFT px-2 ">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mt-3" size="lg" controlId="tokenName">
            <Form.Label className="fw-bold">Digital Asset Name</Form.Label>
            <Form.Control
              type="text"
              value={fields.tokenName}
              onChange={handleFieldChange}
              placeholder="Enter the name of your Digital Asset (27 chars max)"
            />
          </Form.Group>
          <Form.Group className="mt-3" size="lg" controlId="orgName">
            <Form.Label className="fw-bold">Org Name</Form.Label>
            <Form.Control
              value={userOrg}
              plaintext
              readOnly
            />
          </Form.Group>
          <Form.Group className="mt-3" size="lg" controlId="description">
            <Form.Label className="fw-bold">Digital Asset Description</Form.Label>
            <Form.Control
              as="textarea"
              value={fields.description}
              onChange={handleFieldChange}
              placeholder="Provide detailed description"
            />
          </Form.Group>
          <Form.Group className="mt-3" size="lg" controlId="descrShort">
            <Form.Label className="fw-bold">Digital Asset Short Description</Form.Label>
            <Form.Control
              type="text"
              value={fields.descrShort}
              onChange={handleFieldChange}
              placeholder="Provide short description"
            />
          </Form.Group>
          <Form.Group className="mt-3" size="lg" controlId="qty">
            <Form.Label className="fw-bold">Number of tokens (Enter 1 for NFT)</Form.Label>
            <Form.Control
              min="0"
              type="number"
              value={fields.qty}
              onChange={handleFieldChange}
              placeholder="1"
            />
          </Form.Group>
          <Form.Group className="mt-3" size="lg" controlId="tokenChain">
            <Form.Label className="fw-bold">Token Chain</Form.Label>
            <Form.Control
              value="ALGO"
              plaintext readOnly
            />
          </Form.Group>
          <Form.Group className="mt-3" size="lg" controlId="externalURL">
            <Form.Label className="fw-bold">External URL</Form.Label>
            <Form.Control
              as="textarea"
              value={fields.externalURL}
              onChange={handleFieldChange}
              placeholder="Link any external URL for this campaign"
            />
          </Form.Group>
          <Form.Group className="mt-3" controlId="acceptDonation">
            <Form.Label className="fw-bold">Accept Donation </Form.Label>
            <Form.Switch
              onChange={onSwitchAction}
              id="custom-switch"
              label="Show Donate Button for this NFT?"
              checked={acceptDonation}

            />
          </Form.Group>
          <Form.Group className="mt-3" size="lg" controlId="imageURL">
            <Form.Label className="fw-bold">Attachment</Form.Label>
            <Form.Control className="fileChoose" onChange={handleFileChange} type="file" />
          </Form.Group>
          <div className="mx-auto justify-content-md-center mb-3">
            <LoaderButton
              block
              type="submit"
              size="lg"
              variant="primary"
              className="mt-3 mx-auto justify-content-md-center"
              isLoading={isLoading}
              disabled={!validateForm()}
              style={{ 'backgroundColor': currentOrgObj.orgColorTheme }}
            >
              Create Asset
            </LoaderButton>
          </div>

        </Form>
        <div>

          {displayInfo ? <DisplayInfo /> : null}
        </div>
      </div>
      {!isLoading ? <Footer /> : null}
    </div>
  );
}