
import React from "react";
import "./Contact.css";

import { useAppContext } from "../lib/contextLib";
import Logo from '../images/-logos_blue.png';  //  src/images
import { PopupButton } from "react-calendly";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";

export default function Contact() {
    let history = useHistory();
    const { setcurrentOrgLogo } = useAppContext();
    setcurrentOrgLogo(Logo);

    function renderLander() {
        return (
            <div className="lander">
                <section className="bg-dark text-white sectionpad">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg sectionpad2  ">
                                <h2 className="">Contact our team</h2><br />
                                We are eager to help! Contact us below to learn more about mygiving, our solutions, and our team.
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-light sectionpad">
                    <div>
                        <br />
                        <div className="row justify-content-md-center">


                            <div className="col-sm-6 col-lg-4 mb-3">
                                <h6>Email us</h6>
                                <a href="mailto:hello@mygiving.io">hello@mygiving.io</a>
                            </div>


                            <div className="col-sm-6 col-lg-4 mb-3">
                                
                            <h6>Schedule Demo</h6>
                      
                            <PopupButton className="btn btn-primary" text="Pick a time.." url="https://calendly.com/mygiving2/15min" ></PopupButton>
 
                            </div>

                        </div>

                    </div>
                </section >
                <Button className="btn-sm" onClick={() => history.goBack()}>Back</Button>

            </div >
        );
    }




    return (
        <div className="Contact">

            {renderLander()}
        </div>
    );
}