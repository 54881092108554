import "./ClaimNFT.css";
import { Card, Row, Col, Table, Modal, Image } from "react-bootstrap";
import React, { useState, useEffect, } from "react";
import { useParams } from "react-router-dom";
import { onError } from "../lib/errorLib";
import { API, Storage } from "aws-amplify";
import Form from "react-bootstrap/Form";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../lib/hooksLib";
//import { waitForAlgosignerConfirmation } from "../lib/algosdkclient";
import { waitForAlgoConfirmation } from "../lib/algosdkclient";
import { useAppContext } from "../lib/contextLib";
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "algorand-walletconnect-qrcode-modal";
import algosdk from "algosdk";
import { formatJsonRpcRequest } from "@json-rpc-tools/utils";
import { LinkedinShareButton, TwitterShareButton, FacebookShareButton } from "react-share";
import { FacebookIcon, LinkedinIcon, TwitterIcon } from "react-share";
import config from "../config";
import Footer from "./Footer";
import acceptInstructions from "../images/acceptInstructions.gif";

export default function ClaimNFT() {

    const [nfts, setnfts] = useState([]);
    const [holderArray, setholderArray] = useState([]);
    const [pageTitle, setpageTitle] = useState("");
    const [externalURL, setexternalURL] = useState("");
    const { id, nft } = useParams();
    const [walletAdd, setwalletAdd] = useState([]);
    const [walletBal, setwalletBal] = useState([]);
    const [availQty, setavailQty] = useState("0");
    const [balExists, setbalExists] = useState(false);
    const { currentOrgObj, setcurrentOrgObj, setcurrentOrgLogo } = useAppContext();
    const [isConnectedToWallet, setisConnectedToWallet] = useState(false);
    const [showClaim, setshowClaim] = useState(true);
    const [showEmail, setshowEmail] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [OptInisLoading, setOptInisLoading] = useState(false);
    const [errormsgFormat, seterrormsgFormat] = useState(true);
    const [verifyisLoading, setverifyisLoading] = useState(false);
    const [displayInfo, setdisplayInfo] = useState(false);
    const [dispMsg, setdispMsg] = useState([]);
    const [accountMsg, setaccountMsg] = useState([]);
    const [accountExists, setaccountExists] = useState(false);
    const [accountLink, setaccountLink] = useState("");
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [fields, handleFieldChange] = useFormFields({
        destAddress: "",
        nickName: "",
    });

    const connector = new WalletConnect({
        bridge: "https://bridge.walletconnect.org",
        qrcodeModal: QRCodeModal,
    });


    let search = window.location.search;
    let params = new URLSearchParams(search);
    const token = params.get('token');
    //console.log("current org Object" + JSON.stringify(currentOrgObj, 2));


    async function handleSubmit(event) {
        event.preventDefault();

        setverifyisLoading(true);
        setdisplayInfo(false);
        handleShow();
        await optInNFT(fields, nfts);
        handleClose();
    }

    async function requestOptwithWalletConnect(assetIDInt, fundBody) {

        //let wallet;
        //const algodClient = new algosdk.Algodv2('', 'https://testnet.algoexplorerapi.io/', '');
        //const algodClient = new algosdk.Algodv2('', config.ALGO_EXP_API, '');
        console.log()

        const server = config.ALGO_SERVER;
        const port = "";
        const token = {
            "x-api-key": config.XAPI_KEY // fill in yours
        };

        const algodClient = new algosdk.Algodv2(token, server, port);

        //console.log("process.env.ALGO_SERVER" + config.ALGO_SERVER + "~" + config.XAPI_KEY);
        let txParams = await algodClient.getTransactionParams().do();
        txParams.fee = 1000;
        txParams.flatFee = true;


        //console.log("Wallet Address from Wallet Connect + " + walletAdd);
        let note = algosdk.encodeObj(
            JSON.stringify({
                system: "AppCounterDemo",
                date: `${new Date()}`,
            })
        );
        //console.log("walletAdd" + walletAdd);
        try {
            const txn = algosdk.makeAssetTransferTxnWithSuggestedParamsFromObject(
                {
                    assetIndex: assetIDInt,
                    from: walletAdd,
                    to: walletAdd,
                    amount: 0,
                    type: 'axfer',
                    note: note,
                    suggestedParams: txParams
                });

            //console.log("txn--->" + txn);
            const encodedTxn0 = algosdk.encodeUnsignedTransaction(txn);

            const encodedTxnBuffer0 = Buffer.from(encodedTxn0).toString("base64");
            const requestParams = [[{ txn: encodedTxnBuffer0, message: 'demo app counter increment app txn' }]];
            const request = formatJsonRpcRequest("algo_signTxn", requestParams);
            const result = await connector.sendCustomRequest(request);
            const rawSignedTxn = result.map(element => {
                return element ? new Uint8Array(Buffer.from(element, "base64")) : null;
            });

            const sentTxn = await algodClient.sendRawTransaction(rawSignedTxn).do();
            const txId = sentTxn.txId;

            await waitForAlgoConfirmation(algodClient, txId, 4);
            //const status = await waitForAlgosignerConfirmation(sentTxn);
            //console.log(JSON.stringify(status, 2));

            fundBody.action = "optIn";
            await API.post("mygiving", '/optIn', {
                body: fundBody
            });
            setOptInisLoading(false);
            setverifyisLoading(false);
            seterrormsgFormat(false);
            setshowClaim(false);
            setdispMsg("Successfully Claimed!!");
            await connector.killSession();
            setdisplayInfo(true);
            setshowEmail(false);
            //handleClose();
        }
        catch (e) {

            setOptInisLoading(false);
            seterrormsgFormat(true);
            setdispMsg(e.message);
            setdisplayInfo(true);
            setverifyisLoading(false);
            //handleClose();

        }


    }



    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }



    const onClaim = async () => {

        setshowClaim(false);

        try {

            //setisConnectedToWallet(false);
            //await walletConnect();



            if (walletAdd.length > 1) {
                setdisplayInfo(false);

                if (token) {
                    setshowEmail(true);

                } else {
                    setOptInisLoading(true);
                    await optInNFT(fields, nfts);

                }
            } else {
                setOptInisLoading(false);
                seterrormsgFormat(true);
                setdispMsg("No wallet found");
                setdisplayInfo(true);
            }
        }
        catch (e) {
            setOptInisLoading(false);
            seterrormsgFormat(true);
            setdispMsg("No wallet found");
            setdisplayInfo(true);
        }
    }

    const onConnect = async () => {

        try {

            setisConnectedToWallet(false);
            await walletConnect();
            console.log("After connecting" + walletAdd);

        }
        catch (e) {
            setOptInisLoading(false);
            seterrormsgFormat(true);
            setdispMsg("No wallet found");
            setdisplayInfo(true);
        }
    }

    function validateForm() {
        //console.log("file" + file);
        return (fields.nickName.length > 2 && fields.nickName.length < 21 && fields.nickName.match(/^[0-9a-zA-Z]+$/));
    }



    async function walletConnect() {
        

        try {
            // Create a connector

            //console.log("connector" + connector + "~~~" + connector.connected);

            // Check if connection is already established
            if (connector.connected) {
                // create new session
                //console.log("session before " + connector.connected);
                await connector.killSession();
                //console.log("session killed" + connector.connected);
            }

            if (!connector.connected) {
                // create new session
                connector.createSession();
                //console.log("session created");
            }

            // Subscribe to connection events
            connector.on("connect", async (error, payload) => {
                if (error) {
                    throw error;
                }
                //console.log("on event connect");
                // Get provided accounts
                const { accounts } = payload.params[0];
                //console.log("Connect =" + accounts);
                setwalletAdd(accounts[0]);
                console.log("config.ALGO_EXP_INDEXER" + config.ALGO_EXP_INDEXER);
                //const url = `https://testnet.algoexplorerapi.io/v2/accounts/${accounts[0]}`;
                const url = config.ALGO_EXP_INDEXER + `accounts/${accounts[0]}`;
                setisConnectedToWallet(true);

                window.fetch(url, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data) {
                            console.log(data);
                            if (data.account) {
                                if (data.account.address === accounts[0] && data.account.amount) {
                                    //console.log(data.assets.length, (data.assets.length + 1) * 100000);
                                    let minFactor = 2;
                                    let assetsLength = 0;
                                    if (data.account.assets) {
                                        for (var k = 0; k < data.account.assets.length; k++) {
                                            if (data.account.assets[k].amount > 0) {
                                                assetsLength++;
                                            }
                                        }
                                        minFactor = assetsLength + 2;
                                    }
                                    if (data.account.amount < (minFactor) * 101000) {
                                        setwalletBal(0);
                                        setbalExists(true);
                                        console.log("Minimum Balance did not meet. Funding 0.1 ALGO!");
                                    } else {
                                        setwalletBal(data.account.amount);

                                    }
                                    console.log("Wallet Balance" + data.account.amount);
                                }
                            }
                        }
                    });

                var nickName = await API.get("mygiving", `/checkWeb3AccountExists/${accounts[0]}`);
                if (nickName.hasOwnProperty("nickName")) {
                    fields.nickName = nickName.nickName;

                    setaccountMsg("Your shareable link " + window.location.origin + "/wallets/" + fields.nickName);
                    setaccountLink(window.location.origin + "/wallets/" + fields.nickName);
                    setaccountExists(true);
                }
            });

            connector.on("session_update", (error, payload) => {
                if (error) {
                    console.log("session update error" + error);
                    throw error;
                }

                // Get updated accounts 
                //const { accounts } = payload.params[0];
                //console.log(accounts);
            });

            connector.on("disconnect", (error, payload) => {
                if (error) {
                    throw error;
                }
            });
            //console.log("done with function");

        } catch (err) {
            console.error(err);
            setdispMsg("err.message");
            setdisplayInfo(true);
        }
    }






    async function optInNFT(fields, nfts) {
        console.log(nfts, parseInt(nfts[0].assetID));
        var assetIDInt = parseInt(nfts[0].assetID);


        try {
            //console.log("token:" + token);
            var fundBody = {
                destAddress: walletAdd,
                nickName: fields.nickName,
                token: token,
                nft: nfts[0].sk,
                email: fields.destAddress,
                assetID: assetIDInt,
                action: "N",
                orgName: id,
                balExists: balExists
            };

            if (token) {

                /*
                await window.AlgoSigner.connect({
                    ledger: 'TestNet'
                });
                const acctBal = await window.AlgoSigner.indexer({
                    ledger: 'TestNet', path: `/v2/accounts/${acct0}`
                });
                */
                var resultFund;
                //console.log("acctBal.account.amount" + acctBal.account.amount);

                //if (acctBal.hasOwnProperty('account') && parseInt(acctBal.account.amount) > 1000000) {
                if (parseInt(walletBal) > 100000) {
                    fundBody.action = "checkToken";
                    resultFund = await API.post("mygiving", '/optIn', {
                        body: fundBody
                    });
                } else {
                    fundBody.action = "fundAccount";
                    resultFund = await API.post("mygiving", '/optIn', {
                        body: fundBody
                    });
                }
                //console.log(JSON.stringify(resultFund, 2));
                if (resultFund.hasOwnProperty("error")) {
                    setverifyisLoading(false);
                    seterrormsgFormat(true);
                    setdispMsg(resultFund.error);
                    setdisplayInfo(true);
                    return "";

                }
                
                if (resultFund.hasOwnProperty("nickNameCreated")) {

                    setaccountLink(window.location.origin + "/wallets/" + fields.nickName);
                    setaccountMsg("Congratulations!! You have claimed the nick name to your wallet. Your shareable link " + window.location.origin + "/wallets/" + fields.nickName);
                    setaccountExists(true);

                }
                await sleep(3000);
                //await requestOpt(accts, assetIDInt, fundBody);
                await requestOptwithWalletConnect(assetIDInt, fundBody);



                return "";
            } else {
                seterrormsgFormat(true);
                setdispMsg("No Token found!!");
                setdisplayInfo(true);
                setverifyisLoading(false);
                setOptInisLoading(false);

            }

            return "";



        }
        catch (e) {
            //alert("error:" + e);
            console.log(e);
            //console.log(JSON.stringify(signedTx, null, 2));
        }
        setIsLoading(false);

        return "";

    }

    useEffect(() => {
        async function onLoad() {

            try {
                var org = await API.get("mygiving", `/organization/${id}`);
                setcurrentOrgObj(org);
                setcurrentOrgLogo(org.orgBannerLogo);

                var nftsDetails = await API.get("mygiving", `/organization/${id}/NFTS/${nft}`);
                var nfts = nftsDetails['nft'];
                var holderArray = nftsDetails['assetHolders'].holdersArray;


                //console.log(nfts);

                //Object.entries(nfts).forEach(async (nft)=>{nft.imageURL=await Storage.vault.get(nft.imageURL)});
                for (var i = 0; i <= nfts.length - 1; i++) {
                    if (nfts[i].imageURL) {
                        setpageTitle(nfts[i].name);
                        setexternalURL(nfts[i].externalURL);
                        nfts[i].imageURL = await Storage.get(nfts[i].imageURL, { level: 'public' });
                    }

                }
                //nfts[4].imageURL = await Storage.vault.get(nfts[4].imageURL);
                setnfts(nfts);
                setholderArray(holderArray);
                setavailQty(nftsDetails['assetHolders'].totalQty);

            } catch (e) {
                onError(e);
            }

            setIsLoading(false);

        }
        onLoad();
    }, [id, nft, setcurrentOrgObj, setcurrentOrgLogo]);

    /*
    function loadNfts() {
        return API.get("mygiving", `/organization/${id}/NFTS`);
    }
    */

    const DisplayInfo = () => (
        <div className="text-wrap text-truncate">
            {errormsgFormat ?
                <div id="dispInfo" className="alert alert-danger   padding-lg " role="alert">
                    <span>{dispMsg}</span>
                </div>
                :
                <div id="dispInfo" className="alert alert-success   padding-lg " role="alert">
                    <span>{dispMsg}</span>
                </div>
            }
        </div>
    );

    const AccountCreated = () => (
        <div className="text-wrap text-truncate">

            <div id="dispInfo" className="alert alert-success   padding-lg " role="alert">
                <span className="text-primary">{accountMsg}</span>
            </div>

        </div>
    );

    const linkTag = (assetID) => { return (<><a target="_blank" rel="noreferrer" href={config.PURESTAKE_API + "/asset/" + assetID}>{assetID}</a></>) };

    function rendernftsList(nfts) {
        return (
            <>
                <div>
                    <div className="row">
                        <Row xs={1} className="g-4 mx-auto justify-content-md-center">
                            {nfts.map(({ sk, description, imageURL, name, descrShort, qty, tokenAddress, tokenChain, createdAt, assetID, assetHolders }) => (
                                <Col key={sk}>
                                    <Card className="NFTListItem-main-card mx-auto"   >
                                        <div className="imageHolder">
                                            <Card.Img className="NFTListItem-img mx-auto justify-content-md-center" src={imageURL} />
                                        </div>

                                    </Card>
                                    <br />
                                    <Table className="tableDiv">

                                        <tbody>
                                            <tr>
                                                <td>Name</td>
                                                <td className="fw-bold">{name}</td>
                                            </tr>
                                            <tr>
                                                <td>Description</td>
                                                <td className="fw-bold">{description}</td>
                                            </tr>
                                            <tr>
                                                <td>Short Description</td>
                                                <td className="fw-bold">{descrShort}</td>
                                            </tr>
                                            <tr>
                                                <td>Total Quantity </td>
                                                <td className="fw-bold"> {qty}</td>
                                            </tr>
                                            <tr>
                                                <td>Available Quantity </td>
                                                <td className="fw-bold"> {availQty}</td>
                                            </tr>
                                            <tr>
                                                <td>Asset Details</td>
                                                <td className="fw-bold"> {linkTag(assetID)}</td>
                                            </tr>

                                        </tbody>
                                    </Table>

                                </Col>
                            ))}

                        </Row>


                    </div>

                    <div class="container">
                        <div class="row row-cols-auto">
                            <FacebookShareButton className="px-2" url={window.location} title="Please donate">
                                <FacebookIcon round size={32} />
                            </FacebookShareButton>
                            <TwitterShareButton className="px-2" url={window.location} title="Please donate">
                                <TwitterIcon round size={32} />
                            </TwitterShareButton>
                            <LinkedinShareButton className="px-2" url={window.location} title="Please donate">
                                <LinkedinIcon round size={32} />
                            </LinkedinShareButton>

                        </div></div>
                    {!token ?
                        <div className="row sectionpad">


                            <h3>Asset Holders</h3>
                            <Table className="tableDiv">
                                <thead>
                                    <tr>
                                        <th>Wallets</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {holderArray.map((address, index) => {
                                        return (

                                            <tr key={index} data-item={address}  >



                                                <td>

                                                    <a target="_blank" rel="noreferrer" href={window.location.origin + "/wallets/" + ((address.split("|")[1].length > 0) ? address.split("|")[1] : address.split("|")[0])} >{(address.split("|")[1].length > 0) ? address.split("|")[1] : address.split("|")[0].substring(0, 5) + "..." + address.split("|")[0].substring(address.split("|")[0].length - 5)} </a>
                                                </td>

                                            </tr>);
                                    })}
                                </tbody>

                            </Table>


                        </div> : null}
                </div>
            </>
        );
    }



    function rendernfts() {
        return (
            <div className="nfts">
                <div className="pb-3 border-bottom mb-3">
                    <h2 className="mt-4 ">{pageTitle} </h2>
                    <a target="_blank" rel="noreferrer" href={externalURL} class="link-primary">{externalURL}</a>
                </div>
                {!isLoading && rendernftsList(nfts)}
            </div>
        );
    }



    const Connect = () => (
        <div id="connect">
            <LoaderButton
                block
                size="lg"
                type="submit"
                variant="success"
                isLoading={verifyisLoading}
                onClick={onConnect}
                style={{ 'backgroundColor': currentOrgObj.orgColorTheme }}

            >
                Connect to Wallet
            </LoaderButton>

        </div>

    );


    const ClaimButton = () => (

        <div className="justify-content-md-center" >

            <LoaderButton
                block
                size="lg"
                type="submit"
                variant="success"
                isLoading={OptInisLoading}
                onClick={onClaim}
                style={{ 'backgroundColor': currentOrgObj.orgColorTheme }}
            >
                Claim
            </LoaderButton>
        </div>


    );





    const CreateW3Account = () => (
        <div id="connect" className="mx-auto fw-bold pb-3">
            Connected to {walletAdd.substring(0, 5) + "..." + walletAdd.substring(walletAdd.length - 5)}


        </div>
    )


    return (
        <>
            <div className="Home container">
                <div className="mt-1 bannerImg  container" style={{
                    backgroundImage: `url(${currentOrgObj.orgBannerImage})`,
                }}>

                    <div className="text-block  ">
                        <h4>{currentOrgObj.orgWelcomeMessageHeader}</h4>
                        <p>{currentOrgObj.orgWelcomeMessage}</p>
                    </div>


                </div>
                {token ? (
                    <div className="connectPublicBtn">

                        {token ? (isConnectedToWallet ? <CreateW3Account /> : <Connect />) : null}
                    </div>
                ) : null}
                <div>{accountExists ? <a target="_blank" rel="noreferrer" href={accountLink} > <AccountCreated /> </a> : null} </div>
                <div className="mt-1 mb-1">
                    {displayInfo ? <DisplayInfo /> : null}
                </div>

                {token && isConnectedToWallet && showClaim ? <ClaimButton /> : null}





                <div className="mt-3 mb-2">

                    {showEmail && isConnectedToWallet ?
                        <div id="results" className="justify-content-md-center results ">
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="destAddress" size="lg">
                                    <Form.Label>Enter the email Address</Form.Label>
                                    <Form.Control
                                        autoFocus
                                        type="email"
                                        onChange={handleFieldChange}
                                        value={fields.destAddress}
                                    />
                                </Form.Group>
                                <Form.Group className="mt-2" controlId="nickName" size="lg">
                                    <Form.Label>Enter a nick name for your wallet Address that you can share with everyone. Nick name should have a minimum of 3 characters and a maximum of 20 characters. No spaces or special characters allowed </Form.Label>
                                    <Form.Control
                                        type="text"
                                        onChange={handleFieldChange}
                                        value={fields.nickName}
                                    />
                                </Form.Group>
                                <LoaderButton
                                    block
                                    size="lg"
                                    type="submit"
                                    variant="success"
                                    isLoading={verifyisLoading}
                                    disabled={!validateForm()}
                                    style={{ 'backgroundColor': currentOrgObj.orgColorTheme }}
                                >
                                    Verify
                                </LoaderButton>
                            </Form>
                        </div>
                        : null}
                </div>
                {rendernfts()}

            </div>
            {!isLoading ? <Footer /> : null}
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header >
                    <Modal.Title id="contained-modal-title-vcenter">Please complete the transaction from your mobile Algorand wallet</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    This could take a couple of minutes and you will get a series of prompts on your mobile wallet. Please be sure to click Confirm and Accept.
                    <hr />
                    <div className="modalDiv mx-auto   ">
                        <Image className="modalImage mx-auto" src={acceptInstructions} responsive />
                    </div>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>

    );
}