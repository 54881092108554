import "./OrgCampaignHome.css";
import { Button, Card, Row, Col, Table } from "react-bootstrap";
import React, { useState, useEffect, } from "react";
import { useParams } from "react-router-dom";
import { onError } from "../lib/errorLib";
import { Link } from "react-router-dom";
import { API, Storage } from "aws-amplify";
import { useAppContext } from "../lib/contextLib";
import Footer from "./Footer";
import YouTube from 'react-youtube';
import { LinkedinShareButton, TwitterShareButton, FacebookShareButton } from "react-share";
import { FacebookIcon, LinkedinIcon, TwitterIcon } from "react-share";

export default function OrgCampaignHome() {
    const [nfts, setnfts] = useState([]);
    const [org, setorg] = useState([]);
    const [campaign, setcampaign] = useState([]);
    const { id, cid } = useParams();
    const { setcurrentOrgLogo, setcurrentOrgObj } = useAppContext();
    const [holderArray, setholderArray] = useState([]);

    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        async function onLoad() {



            try {

                var org = await API.get("mygiving", `/organization/${id}`);
                //console.log("disp:"+org);

                setorg(org);

                var campaign = await API.get("mygiving", `/organization/${id}/${cid}`);
                setcampaign(campaign);

                var nftColls = await API.get("mygiving", `/organization/${id}/${cid}/NFTS`);
                var nfts = nftColls.nfts;
                var holderArray = nftColls.assetHoldersArrayUnique;
                console.log("Holder Array:" + holderArray);
                setholderArray(holderArray);
                //Object.entries(nfts).forEach(async (nft)=>{nft.imageURL=await Storage.vault.get(nft.imageURL)});
                for (var i = 0; i <= nfts.length - 1; i++) {
                    if (nfts[i].imageURL) {
                        nfts[i].imageURL = await Storage.get(nfts[i].imageURL, { level: 'public' });
                    }

                }
                //nfts[4].imageURL = await Storage.vault.get(nfts[4].imageURL);
                setnfts(nfts);
                //console.log("About to setup org"+org.orgBannerLogo);
                setcurrentOrgLogo(org.orgBannerLogo);
                //setcurrentOrgObj(org);

            } catch (e) {
                onError(e);
            }

            setIsLoading(false);

        }
        onLoad();

    }, [id, cid, setcurrentOrgLogo]);

    /*
    function loadNfts() {
        return API.get("mygiving", `/organization/${id}/NFTS`);
    }
*/
    setcurrentOrgLogo(org.orgBannerLogo);
    setcurrentOrgObj(org);
    //console.log("currentOrg - After:" + currentOrg + "id:", id);
    function rendernftsList(nfts) {
        return (
            <>
                <Row xs={1} className="g-4 justify-content-md-center mt-3">
                    {nfts.map(({ sk, description, imageURL, name, qty, tokenAddress, tokenChain, createdAt }) => (
                        <Col key={sk}>
                            <Card className="OrgListItem-main-card text-center"   >
                                <div className="imageHolder mx-auto">
                                    <Card.Img className="OrgListItem-img mx-auto" src={imageURL} />
                                </div>
                                <Card.Body>
                                    <Card.Title className=" fw-bold text-bold text-center">{name}</Card.Title>
                                    <Card.Text className="small text-center">
                                        Total Qty:{qty}
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer className="mx-auto">
                                    <Link to={`/org/${id}/nfts/${sk.split("#")[1]}`}>
                                        <Button style={{ 'backgroundColor': org.orgColorTheme }}>
                                            Details
                                        </Button>
                                    </Link>
                                </Card.Footer>

                            </Card>
                        </Col>

                    ))}

                </Row>

                <div >
                    <h2 className="mt-4 mb-3 border-bottom"> Holders </h2>
                    <Table className="tableDiv">


                        <tbody>
                            {holderArray.map((address, index) => {
                                return (

                                    <tr key={index} data-item={address}  >



                                        <td>

                                            <a target="_blank" rel="noreferrer" href={window.location.origin + "/wallets/" + ((address.split("|")[1].length > 0) ? address.split("|")[1] : address.split("|")[0])} >{(address.split("|")[1].length > 0) ? address.split("|")[1] : address.split("|")[0].substring(0, 5) + "..." + address.split("|")[0].substring(address.split("|")[0].length - 5)} </a>
                                        </td>

                                    </tr>);
                            })}
                        </tbody>

                    </Table>


                </div>
            </>
        );
    }



    function rendernfts() {
        return (
            <div className="nfts">

                {!isLoading ?
                    <> <div className="mt-1 border-bottom mb-2 mt-3 mx-auto">
                        <h2  >{campaign.campaignName}</h2>
                        <div  >

                            <FacebookShareButton className="px-2" url={window.location} title="Please donate">
                                <FacebookIcon round size={20} />
                            </FacebookShareButton>
                            <TwitterShareButton className="px-2" url={window.location} title="Please donate">
                                <TwitterIcon round size={20} />
                            </TwitterShareButton>
                            <LinkedinShareButton className="px-2" url={window.location} title="Please donate">
                                <LinkedinIcon round size={20} />
                            </LinkedinShareButton>


                        </div><br /></div>

                        <div className="mt-1 border-bottom mb-1 mx-auto">
                            <div className="container">
                                <div className="row mx-auto">
                                    <div className="col-lg sectionpad2 mx-auto">

                                        {campaign.description}
                                    </div>


                                </div>
                                <div className="yt">


                                    <YouTube videoId={campaign.videoPublicURL} />



                                </div>

                            </div>
                            <h2 className="mt-4 mb-3 "> Collections</h2>
                        </div>
                    </>
                    : null}
                {!isLoading && rendernftsList(nfts)}

            </div>

        );
    }

    return (
        <div className="Home  container" >
            <div className="mt-1 bannerImg  container" style={{
                backgroundImage: `url(${org.orgBannerImage})`,
            }}>

                <div className="text-block">
                    <h4>{org.orgWelcomeMessageHeader}</h4>
                    <p>{org.orgWelcomeMessage}</p>
                </div>


            </div>
            {rendernfts()}
            {!isLoading ? <Footer /> : null}

        </div>
    );
}